/* .div-brand:hover + .div-image img {
  transition: transform 500ms ease-in-out;
  transform: scale(1);
} */
/* .div-brand + .div-image img {
  transform: scale(0.6);
} */
/* .div-brand + .div-image img.image-ton {
  transform: scale(0.8);
} */
/* .div-image:hover img {
  transition: transform 500ms ease-in-out;
  transform: scale(1);
} */
/* .div-image img {
  transform: scale(0.6);
} */
/* @media (min-width: 835px) and (max-width: 1194px) {
  .div-brand + .div-image img.image-ton {
    transform: scale(0.9);
  }
} */

@media (min-width: 768px) and (max-width: 1279px) {
  .div-brand + .div-image img.image-stone {
    transform: scale(0.7);
    margin-bottom: -118px;
  }
}
