/* Customize the scrollbar styles */
#sidemenu {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

aside {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

#sidemenu::-webkit-scrollbar {
  display: none; /* Hide the default scrollbar on webkit browsers */
}

aside::-webkit-scrollbar {
  display: none; /* Hide the default scrollbar on webkit browsers */
}

#sidemenu::-webkit-scrollbar-thumb {
  background-color: transparent;
}

#sidemenu::-webkit-scrollbar-track {
  background-color: transparent;
}

aside::-webkit-scrollbar-thumb {
  background-color: transparent;
}

aside::-webkit-scrollbar-track {
  background-color: transparent;
}
