/**
 * This injects Tailwind's base styles and any base styles registered by
 * plugins.
 */
@tailwind base;

/**
 * This injects Tailwind's component classes and any component classes
 * registered by plugins.
 */
@tailwind components;

/**
 * This injects Tailwind's utility classes and any utility classes registered
 * by plugins.
 */
@tailwind utilities;

/**
 * Use this directive to control where Tailwind injects the hover, focus,
 * responsive, dark mode, and other variants of each class.
 *
 * If omitted, Tailwind will append these classes to the very end of
 * your stylesheet by default.
 */
@tailwind variants;
@font-face {
  font-family: ActionCondensedBold;
  src: url(/src/fonts/ActionCondBoldWeb-Grade3.ttf) format('truetype');
}
@font-face {
  font-family: ArialBold;
  src: url(/src/fonts/Arial-Bold.ttf) format('truetype');
}
@font-face {
  font-family: Arial;
  src: url(/src/fonts/Arial.ttf) format('truetype');
}
@font-face {
  font-family: InterV;
  src: url(/src/fonts/Inter-V.ttf) format('truetype');
}
@font-face {
  font-family: MyriadProRegular;
  src: url(/src/fonts/MyriadPro-Regular.otf) format('truetype');
}
@font-face {
  font-family: SharonDisplay;
  src: url(/src/fonts/Sharon-Display-Variable.ttf) format('truetype');
}
@font-face {
  font-family: TonCondensedVF;
  src: url(/src/fonts/TonCondensedVF.ttf) format('truetype');
}
@font-face {
  font-family: SharonItalic;
  src: url(/src/fonts/SharonSerif-Italic.otf) format('truetype');
}
@font-face {
  font-family: SharonRegular;
  src: url(/src/fonts/SharonSans-Regular.otf) format('truetype');
}
@font-face {
  font-family: SharonMedium;
  src: url(/src/fonts/SharonSans-Medium.otf) format('truetype');
}
@font-face {
  font-family: SharonLight;
  src: url(/src/fonts/SharonSans-Light.otf) format('truetype');
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}
