.rangeInput {
  --range-knob-color: #00af55;
  background-color: #00af55;
}

@media (min-width: 1500px) {
  .rangeInput.rangeInput.rangeInput {
    width: 18vw;
  }
}

.rangeInput::-webkit-slider-thumb {
  background-color: var(--range-knob-color);
}
.rangeInput:active::-webkit-slider-thumb {
  background-color: var(--range-knob-color);
}

.rangeInput::-moz-range-thumb {
  background-color: var(--range-knob-color);
}

.rangeInput::-ms-thumb {
  background-color: var(--range-knob-color);
}

.rangeInput::-webkit-progress-value {
  background-color: var(--range-knob-color);
}

.rangeInput::-moz-range-progress {
  background-color: var(--range-knob-color);
}

.rangeInput::-ms-fill-lower {
  background-color: var(--range-knob-color);
}
