@media (max-width: 1279px) {
  .menu-btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 24px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    z-index: 41;
  }
  .menu-btn__burger {
    width: 24px;
    height: 2px;
    background: #e1fadc;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
  }
  .menu-btn__burger::before,
  .menu-btn__burger::after {
    content: '';
    position: absolute;
    width: 24px;
    height: 2px;
    background: #e1fadc;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
  }
  .ton .menu-btn__burger {
    width: 24px;
    height: 2px;
    background: #003c00;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
  }
  .ton .menu-btn__burger::before,
  .ton .menu-btn__burger::after {
    content: '';
    position: absolute;
    width: 24px;
    height: 2px;
    background: #003c00;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
  }
  .menu-btn__burger::before {
    transform: translateY(-8px);
  }
  .menu-btn__burger::after {
    transform: translateY(8px);
  }
  .menu-btn.open .menu-btn__burger {
    background: transparent;
    box-shadow: none;
  }
  .menu-btn.open .menu-btn__burger::before {
    transform: rotate(45deg);
  }
  .menu-btn.open .menu-btn__burger::after {
    transform: rotate(-45deg);
  }
}
