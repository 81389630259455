.ais-SearchBox-input {
  background-color: transparent;
  outline: none;
  padding-left: 32px;
  font-family: 'Inter';
  padding-bottom: 0.25rem;
  font-size: 0.8rem;
  width: 16rem;
  @media (max-width: 767px) {
    max-width: 85px;
  }
  @media (max-width: 1279px) {
    width: 18rem;
  }
}
.ais-Hits {
  width: 100% !important;
  @media (max-width: 1279px) {
    width: unset;
  }
}
.ais-Hits-list {
  max-height: 400px;
  overflow-y: auto;
  padding-bottom: 10px;
  padding-top: 30px;
  border-radius: 6px;
  background-color: #e1fadc;
  position: absolute;
  top: 120px;
  left: 440px;
  @media screen and (max-width: 1279px) {
    position: absolute;
    margin-top: 15px;
    width: 22% !important;
    top: 70px;
    left: 921px;
  }

  @media screen and (max-width: 834px) {
    position: absolute;
    margin-top: 15px;
    width: 32% !important;
    top: 70px;
    left: 291px;
  }
  @media screen and (max-width: 767px) {
    position: absolute;
    margin-top: 15px;
    width: 90% !important;
    top: 150px;
    left: 35px;
  }
  @-moz-document url-prefix() {
    @media screen and (max-width: 430px) {
      position: absolute;
      margin-top: 15px;
      width: 80% !important;
      top: 150px;
      left: 35px;
    }
  }
}
/* Customize the scrollbar styles */
.ais-Hits-list {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.ais-Hits-list::-webkit-scrollbar {
  display: none; /* Hide the default scrollbar on webkit browsers */
}

.ais-Hits-list::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.ais-Hits-list::-webkit-scrollbar-track {
  background-color: transparent;
}

.custom-SearchBox-input-stone::placeholder {
  color: #e1fadc;
  outline: none;
}
.custom-SearchBox-input-stone:focus {
  color: #e1fadc;
  outline: none;
}
.custom-SearchBox-input-stone:not(:focus) {
  color: #e1fadc;
  outline: none;
}
.custom-SearchBox-input-ton::placeholder {
  color: #004132;
  outline: none;
}
.custom-SearchBox-input-ton:focus {
  color: #004132;
  outline: none;
}
.custom-SearchBox-input-ton:not(:focus) {
  color: #004132;
  outline: none;
}
.ais-SearchBox-input:focus {
  color: #e1fadc;
  outline: none;
}
.ais-SearchBox-input:not(:focus) {
  color: #e1fadc;
  outline: none;
}
.ais-SearchBox-input::placeholder {
  color: #e1fadc;
  outline: none;
}
.ais-SearchBox-form svg {
  width: 20px;
  height: 20px;
  fill: #e1fadc;
  outline: none;
}
.change-icon-color .custom-SearchBox-input-stone::placeholder {
  color: #004132;
}
.change-icon-color .custom-SearchBox-input-stone:focus {
  color: #004132;
}
.change-icon-color .custom-SearchBox-input-stone:not(:focus) {
  color: #004132;
}
.change-icon-color .custom-SearchBox-input-stone::placeholder {
  color: #004132;
}
.change-icon-color .custom-SearchBox-input-ton::placeholder {
  color: #00af55;
}
.change-icon-color .custom-SearchBox-input-ton:focus {
  color: #00af55;
}
.change-icon-color .custom-SearchBox-input-ton:not(:focus) {
  color: #00af55;
}
.change-icon-color .custom-SearchBox-input-ton::placeholder {
  color: #00af55;
}
.change-icon-color .ais-SearchBox-input:focus {
  color: #004132;
}
.change-icon-color .ais-SearchBox-input:not(:focus) {
  color: #004132;
}
.change-icon-color .ais-SearchBox-input::placeholder {
  color: #004132;
}
.change-icon-color svg {
  width: 20px;
  height: 20px;
  fill: #004132;
  outline: none;
}
.ais-SearchBox-form button[type='submit'] {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 25px;
  height: 25px;
  outline: none;
}
.ais-SearchBox-input:focus + .ais-SearchBox-form button[type='submit'] {
  display: none !important;
}
.ais-SearchBox-form button[type='reset'] {
  display: none;
}
.ais-SearchBox-form button[type='reset']:focus {
  display: none;
}
